/* The highest level */
.container {
   all: revert;
  display: grid;
  grid-gap: 5px;
  grid-template-rows: 1fr 6fr 2fr 2fr;
  grid-template-columns: 1fr 8fr 2fr;
 width: 100%;
  height: 800px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  background-color: #e1e0ff;
}

.footer {
  grid-column: 1/4;
  background-color: #fbfbfb0e;
  grid-row: 4;
  padding-top: 15px;
}

.midzone {
  margin-top: 90px;
  clear:both;
  grid-row: 2;
  grid-column: 1 / 4;
  height: auto;
}



/* The second highest level */
.headerKnowIt {
  background-color: #211aa9;
  border: 1px solid #000;
  grid-row: 1;
  grid-column: 1 /  4;
  height: 80px;
  display: inline-block;
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  
}

/* The second highest level */
.rules {
  grid-row: 3;
  grid-column: 2 / 3;
  clear: left;
  width: auto;
  background-color: #f9eeee40;
  font-weight: 700;
  border-top-left-radius: 20px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
font-size: 20px;
list-style: none;
}

/* The second highest level */


.login {
  grid-row: 2;
  grid-column: 2/3;
  border-top-left-radius: 20px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-top: 18px;
  
}

@media (min-width: 768px) {
  .login {
    top: unset;
  }
}

.loginForm {
  height:auto;
  display: flex;
  margin-top: 4px;
    border: 2px;
}


.loginButton {
  height: auto;
  width: 100%;
  background-color: #d8d7ff;
  border-radius: 5px;
  font-size: 21px; 
  border: solid black 2px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.email{
  width: 50%;
  border-radius: 5px;
  background-color: #1cb22209;
}




/* The second highest level */
.category {
  grid-row: 2;
  grid-column: 1 / span 2;
  margin-left: 10px;
  font-size: 25px;
}

.privacy {
  grid-row: 2;
  grid-column: 1 / 4;
  background-color: #15116d6e;
  height: 1400px;
}

h1 {
  display: inline-flex
}

h2 {
  float: left;
  font-size: 33px;
  height: 0px;
  margin-bottom: 1px;
  margin-left: 8px;
  margin-top: 0px;
  padding-bottom: 42px;
  padding-top: 2px;
  width: 98%;
}
 
h3 {
  float: left;
  font-size: 25px;
  height: 40px;
  margin-bottom: 1px;
  margin-left: 8px;
  margin-top: 2px;
  padding-top: 18px;
  width: 75%;
  background-color: #D3BE47;
  border-radius: 5px;
  border: solid black 1px;
  text-align: center;
 }

 h4{
  padding-top: 2px;
    padding-bottom: 155px;
    margin-top: 10px;
    margin-bottom: 1px;
    float: left;
    font-size: 25px;
    height: 40px;
    margin-left: 8px;
    width: 75%;
 }

 h5 {
  border-bottom: solid black 3px;
  padding-top: 2px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 1px;
  float: left;
  font-size: 25px;
  height: 40px;
  margin-left: 8px;
  width: 75%;
 }

 br{
  clear: both;
 }

#myimg {
  float: right;
  height:300px;
}

.satsP{
  clear: both;
}


imgSat {
  float: right;
  height:300px;
  width:400px;
} 

.list{
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  /* align-items: flex-start; */
  list-style: none;
  margin: 59;
  padding: 10px;
  flex-wrap: wrap;
}

.listSatsLong{
  font-size: 20px;
   height: 350px;
   background-color: #00009922;
  padding: 25px;
  margin: 5px;
  font-size: 33px;
 
  width: 80%;
  border: 1px solid #000;
  
  margin: 5px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  
  display: inline-block;
}

.listSatsLong, .satsButton {
 margin: 3px;
}


.lowList, .highList{
  display: inline-flex;
    background-color: #f9eeee40;
    border: 1px solid #000;
    font-size: 25px;
    justify-content: center;
        margin: 2px;
    text-align: center;
        padding: 20px 35px;
}

.highList {
  width: auto;
  
}

.highlight {
  border: 2px solid blue;
}


.navi {
height: 90%;
border: black;
display: inline-flex;
float: left;
background-color: #15116f09;
color: rgb(255, 255, 255);
text-decoration: none;
font-size: 25px;
text-align: center;
align-items: center;
margin: auto;
border-top-left-radius: 20px;
border-top-right-radius: 5px;
border-bottom-left-radius: 2px;
border-bottom-right-radius: 2px;
}

.navi:hover {
  background-color: rgb(231, 256, 172);
  border-color: rgb(0, 0, 0);
  border-width: 3px;
  color: #de0000;
  border-top-left-radius: 5px;
}

a {
  display: inline-block;
  width: 50px;
  background-color: #bdbdbd;
  color: #000;
  font-size: 17px;
  height: 75px;
  text-align: center;
  text-decoration: none;
}



.privacyButton {

  height: 75px;
border: black;
display: inline-flex;
float: left;
background-color: rgb(231, 172, 172);
color: #000;
text-decoration: none;
font-size: 17px;
text-align: center;
align-items: center;
}



#myimg2{
  position: absolute;
  width: 100%;
  height: auto;
  z-index: -1;
}

#myimg1 {
  position: fixed;
  width: max-content;
  height: 200;
  z-index: -1;
}

.categoryButtons {
  width: 50%;
  background-color: #ffffff0f;
}

/* all the knowit buttons */
.revisionButtons, .nextCategory, .answer button, .knowAnswer {
  background-color: #ffffff0f;
  float: left;
  font-size: 30px;
  border-radius: 3px;
}
.revisionButtons:hover, .nextCategory:hover, .answer button:hover, .knowAnswer:hover {
  background-color: #d3be48;

}


.revisionButtons {
  width: 24%;
  height: 40px;
  font-size: 19px;
}

.nextCategory {
  width: 48%;
 height: 30px; 
 font-size: 19px;
}

.answer button {
  height: 80px;
  width: 48%;
  font-size: 67px;
 }

.knowAnswer {
   clear: right;
     width: 48%;
  height: 80px;
  font-size: 67px;
}

.answerSats {
  font-size: 0px;
}

.answerSatsReveal {
  font-size: medium;
}

.go {
  width: 100px;
  height: 40px;
  background-color: #d3be48;
}

/* fractions */
.top { border-bottom: solid black 1px; 
  margin-block-start: 0px;
  margin-block-end: 0px;
 
}
.bottom{margin-block-start: 0px;
  margin-block-end: 0px;}

.integer{     float: left;
  font-size: 60px;
  margin-block-end: 0px;
  margin-block-start: 0px;
  margin-inline: 5px;}

.satsPaper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fraction {
      display: flex;
      float: left;
      flex-direction: column;
      align-items: center;
      margin-inline: 5px;
      margin-block: 0px;
}

.listReasoning{
  background-color: #00009922;
  align-items: center;
    /* border: 1px solid #000; */
    border-radius: 3px;
    display: flex;
    font-size: 33px;
    height: 300px;
    justify-content: space-between;
    margin: 5px;
    padding: 36px;
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
}

.listReasoningTally{
  background-color: #00009922;
  align-items: center;
    /* border: 1px solid #000; */
    border-radius: 3px;
    display: flex;
    font-size: 25px;
    height: 700px;
    justify-content: space-between;
    margin: 5px;
    padding: 36px;
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
}



.superScript{font-size: small; position: relative;}

.answerWrong {
  background-color: #de5000;
}

.answerRight {
  background-color: chartreuse;
}

.satsQNumber{
  float:left
}

.satsList {
display: block;
align-items: center;
background-color: #d3be48;
border: 1px solid black;
margin: 5px;
padding: 10px;
}

.listSats {
  background-color: #00009922;
  padding: 25px;
  margin: 5px;
  font-size: 33px;
  height: 60px;
  width: 80%;
  border: 1px solid #000;
  display: flex;
  margin: 5px;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
}

.inputAnswer {
height: 40px;
font-size: 33px;
}

.titleKI{
  align-content: center; 
    align-items: center;
    display: flex;
    /* flex-wrap: wrap; */
    font-family: Arial,Helvetica,sans-serif;
    /* justify-content: unset; */
    clear: left; 
    background-color: white;
}
.titleKnowIt
{
  margin-top: 0px;
    font-size: 54px;
    display: flex;
    padding-bottom: 0px;
    margin-left: 0px;
    width: 207px;
    border-right: solid black 3px;
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 11px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-block-end: 0px;
    height: auto;
    background-color: white;
}
.rapidRepeat{
  padding: 14px;
  font-size: 17px;
  width: 128px;
  clear: right;
  /* margin-left: 5px; */
  background-color: white;
  text-align: initial;
}

.break {
  flex-basis: 100%;
  height: 0;
}


.titleKIDiv
{
clear:left;
}
.satsButton {
  width: auto;
    height: auto;
    font-size: 26px;
    background-color: aliceblue;
    border-radius: 3px;
    display: inline-block;
}
.satsButton:hover {
  background-color: #d3be48;
}
.satsButton:visited {
  background-color: #e72525;
}

.score{
  font-size: large;
}
.fractionSet {
  display: flex
}

.chooseList {
  float:left;
}

.chooseListItems {
  float:left;
}

.welcomeDiv{
  background-color: white;
}

.welcome {
  border: solid black 1px
}

.Sats{
  background-color: #211aa9;
}

.List {
  background-color: aquamarine ;
}

.GCSE {
  background-color: #bdbdbd;
}

.text1{
  font-size: 15px;
}

.text2{
  font-size: 20px;
}

.text3{
  font-size: 25px;
}

.text4{
  font-size: 40px;
}

@media (max-width: 1335px){
  .navi {
    height:45%;
    font-size: 21px
  }
 #myimg{
    height: 260px;
  
  }

}

@media (max-width: 1150px){

  #myimg{
    height: 200px;
  
  }

.listReasoning{
  height: 411px;
  padding: 30px;
}

  .navi {
    height:45%;
    font-size: 13px
  }



.inputAnswer{
  height: 60px;
}

.satsP{
  float:left
}

.integer {
  font-size: 35px;
}

h5{
  font: size 18px;
  width: 72%;
}
h3{
  width: 72%
}
}


@media (max-width: 800px) {
  .listSats {font-size: 15px;
  }
  .listReasoning{
    font-size: 29px;
  }
  .integer {
    font-size: 20px;
  }
  .navi {
    font-size: 15px;
    height: 47%
  }
.headerKnowIt {
 display: flex;
 height: 160px
}
h2 { height: 30px;
font-size: 25px;}

.knowAnswer {
   clear: right;
     width: 48%;
  height: 80px;
  font-size: 43px;
}

.answer Button {
  font-size: 43px;
}

.revisionButtons {
  width: 24%;
  height: 40px;
  font-size: 12px;
}

h5{
  font-size: 18px;
  width: 63%;
  height: 50px;}
h3{
  width: 63%;
  height: 60px;
}


}

@media (max-width: 630px){
  h1{ font-size: 1em}

  h5{
    font-size: 18px;
    width: 54%;
    height: 50px;}
  h3{
    width: 54%;
    height: 60px;
    margin-left: 0px;
  }
  h4{
    width: 100%;
  }
#myimg{
  height: 170px;

}
.navi {
  font-size: 13px;
  height: 5%;


}}
@media (max-width: 430px){

  .rapidRepeat {
    background-color: #fff;
    clear: right;
    font-size: 13px;
    padding: 14px;
    width: 86px;
}


  .titleKnowIt {
    -webkit-margin-after: 0;
    background-color: #fff;
    border-right: 3px solid #000;
    display: flex;
    font-size: 38px;
    height: auto;
    margin-block-end: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 0 5px 0 5px;
    width: 140px;
}

  h4{ font-size: 20px;}

  .navi{
font-size: 12px;
height: 30%;
padding: 0px;
}

.headerKnowIt {
display:flex;
  height: 80px;}

  h5{
    font-size: 15px;
    width: 51%;
    height: 70px;}

  h3{
    width: 52%;
    font-size:17px;
    height: 60px;
    margin-left: 0px;
  }
.nextCategory{
  font-size:15px;
}

.revisionButtons{
  font-size: 10px;
}

.knowAnswer {
  font-size: 38px;

}

  h4{
    height: 40px
  }
#myimg{
  height: 140px;

}


}

.instruct {
  clear:left
}